import React from 'react';
import '../App.css';
import './config';

import {Layout, Popconfirm, Table} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;

class FailHistories extends BaseComponent {

    constructor(props) {
        super(props);

        this.columns = [
            {
                title: '表号',
                dataIndex: 'mac',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.mac - b.mac,
            },
            {
                title: '结果',
                dataIndex: 'testResult',
                filterMultiple: false,
                sorter: (a, b) => a.testResult - b.testResult,
                render: (text, record) => (
                    <span style={{color: record.testResult === "通过" ? "#52c41a" : "red"}}>{text}</span>
                ),
            },
            {
                title: 'IMEI',
                dataIndex: 'imei',
                filterMultiple: false,
                sorter: (a, b) => a.imei - b.imei,
                render: (text, record) => (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                ),
            },
            {
                title: 'IMSI',
                dataIndex: 'imsi',
                filterMultiple: false,
                sorter: (a, b) => a.imsi - b.imsi,
                render: (text, record) => (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                ),
            },
            {
                title: 'ICCID',
                dataIndex: 'ccid',
                filterMultiple: false,
                sorter: (a, b) => a.ccid - b.ccid,
                render: (text, record) => (
                    <span style={{wordBreak: 'break-all'}}>{text}</span>
                ),
            },
            {
                title: '电压',
                dataIndex: 'volt',
                filterMultiple: false,
                sorter: (a, b) => a.volt - b.volt,
                render: (text, record) => (
                    <span style={{color: record.voltOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '信号质量',
                dataIndex: 'signal',
                filterMultiple: false,
                sorter: (a, b) => a.signal - b.signal,
                render: (text, record) => (
                    <span style={{color: record.signalOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '版本号',
                dataIndex: 'version',
                filterMultiple: false,
                sorter: (a, b) => a.version - b.version,
                render: (text, record) => (
                    <span style={{color: record.versionOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '读数',
                dataIndex: 'meterValue',
                filterMultiple: false,
                sorter: (a, b) => a.meterValue - b.meterValue,
                render: (text, record) => (
                    <span style={{color: record.meterValueOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '上报时间',
                dataIndex: 'reportTime',
                filterMultiple: false,
                sorter: (a, b) => a.reportTime - b.reportTime,
                render: (text, record) => (
                    <span>{!text ? "" : (text + "").replace(/(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/g, "20$1-$2-$3 $4:$5:$6")}</span>
                ),
            },
            {
                title: '上报周期',
                dataIndex: 'byHour',
                filterMultiple: false,
                sorter: (a, b) => a.byHour - b.byHour,
                render: (text, record) => (
                    <span style={{color: record.byHourOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '上报间隔',
                dataIndex: 'rptInterval',
                filterMultiple: false,
                sorter: (a, b) => a.rptInterval - b.rptInterval,
                render: (text, record) => (
                    <span style={{color: record.rptIntervalOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '采集周期',
                dataIndex: 'readInterval',
                filterMultiple: false,
                sorter: (a, b) => a.readInterval - b.readInterval,
                render: (text, record) => (
                    <span style={{color: record.readIntervalOk ? "" : "red"}}>{text}</span>
                ),
            },
            {
                title: '操作',
                render: (text, record) => (
                    global.getUserLoggedIn() === 'admin' ?
                        <Popconfirm title="确认要删除?" onConfirm={() => this.handleDelete(record.mac)}>
                            <a href="javascript:;">删除</a>
                        </Popconfirm> : ''
                ),
            },
        ];
    }

    state = {
        data: [],
        pagination: {},
    };

    fetchTestResults = (params = {}) => {
        this.fetch({
            path: '/getFailHistories',
            success: (data) => {
                const pagination = {...this.state.pagination};
                pagination.total = data.length;
                this.setState({
                    dataSource: data,
                    pagination,
                });
            }
        });
    };

    handleDelete = mac => {
        const dataSource = [...this.state.dataSource];
        this.fetch({
            path: '/delFailHistory',
            data: {mac: mac},
            success: data => {
                this.setState({dataSource: dataSource.filter(item => item.mac !== mac)});
            }
        })
        ;

    };

    componentDidMount() {
        super.componentDidMount(this.fetchTestResults);
    }

    render() {
        return (
            <div>
                <Content
                    style={{
                        margin: '10px 10px',
                        padding: 24,
                        background: '#fff',
                    }}
                >
                    <Table columns={this.columns} dataSource={this.state.dataSource}/>
                </Content>
            </div>
        );
    }
}

export default FailHistories;
