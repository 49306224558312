import React from 'react';
import '../App.css';
import './config';

import {Layout, Popconfirm, Table, Tabs} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;
const {TabPane} = Tabs;

class Settings extends BaseComponent {

    constructor(props) {
        super(props);

        this.columns = [
            {
                title: '序号',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: '批次',
                dataIndex: 'batch',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.batch - b.batch,
                render: (text, record) => (
                    <span><a href={"/testResult/?" + record.batch} rel="noopener noreferrer" target="_blank">{text}</a></span>
                ),
            },
            {
                title: '设备数',
                dataIndex: 'total',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.total - b.total,
            },
            {
                title: '通过数',
                dataIndex: 'passCount',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.passCount - b.passCount,
            },
            {
                title: '未通过数',
                dataIndex: 'failCount',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.failCount - b.failCount,
            },
            {
                title: '未找到表号',
                dataIndex: 'missCount',
                filterMultiple: false,
                sorter: (a, b) => a.missCount - b.missCount,
            },
            {
                title: '测试人',
                dataIndex: 'tester',
                filterMultiple: false,
                sorter: (a, b) => a.tester - b.tester,
            },
            {
                title: '测试开始时间',
                dataIndex: 'startTime',
                filterMultiple: false,
                sorter: (a, b) => a.startTime - b.startTime,
            },
            {
                title: '测试结束时间',
                dataIndex: 'endTime',
                filterMultiple: false,
                sorter: (a, b) => a.endTime - b.endTime,
            },
            {
                title: '操作',
                render: (text, record) => (
                    <Popconfirm title="确认要删除?" onConfirm={() => this.handleDelete(record.batch)}>
                        <a href="javascript:;">删除</a>
                    </Popconfirm>
                ),
            },
        ];

    }

    state = {
        dataSource: [],
        pagination: {},
        visible: false,
    };

    fetchHistories = (params = {}) => {
        console.log('params:', params);
        this.setState({loading: true});
        this.fetch({
            path: '/getTestHistories',
            success: (data) => {
                const pagination = {...this.state.pagination};
                pagination.total = data.length;
                this.setState({
                    dataSource: data,
                    pagination,
                });
            }
        });
    };

    handleDelete = batch => {
        const dataSource = [...this.state.dataSource];
        this.fetch({
            path: '/delTestHistory',
            data: {batch: batch},
            success: data => {
                this.setState({dataSource: dataSource.filter(item => item.batch !== batch)});
            }
        })
        ;

    };

    componentDidMount() {
        super.componentDidMount(this.fetchHistories);
    }

    render() {
        return (
            <div>
                <Content
                    style={{
                        margin: '10px 10px',
                        padding: 24,
                        background: '#fff',
                    }}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="历史测试结果" key="1">
                            <Table columns={this.columns} dataSource={this.state.dataSource}
                                   onChange={this.onChange}/>
                        </TabPane>
                    </Tabs>
                </Content>
            </div>
        );
    }
}

export default Settings;
