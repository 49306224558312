import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import '../App.css';

import {Button, Form, Icon, Input, Modal, Tabs} from 'antd';
import BaseComponent from "./BaseComponent";

const {TabPane} = Tabs;


class Login extends BaseComponent {
    constructor(props) {
        super(props)
    }

    state = {
        adminLogin: false
    };

    componentDidMount() {
        super.componentDidMount();
        const userLoggedIn = global.getUserLoggedIn();
        this.setState({loginSuccess: userLoggedIn});
    };

    onTabChange = (activeKey) => {
        this.setState({adminLogin: activeKey === "admin"});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            let user = values['user'];
            let pwd = values['pwd'];
            if (this.state.adminLogin) {
                user = 'admin';
                pwd = values['adminPwd'];
            }

            if (!user || !pwd) {
                return false;
            }

            this.fetch({
                path: '/login',
                data: {user: user, pwd: pwd}
                , success: data => {
                    global.userLoggedIn(user);
                    global.setSession(data.sessionId);
                    window.location.href = "/";
                }
            });

        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Router>

                <Modal
                    width={350}
                    visible={true}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer=""
                >

                    <Form layout="vertical" style={{textAlign: 'center'}} onSubmit={this.handleSubmit}>
                        <Tabs defaultActiveKey="tester" onChange={this.onTabChange}>
                            <TabPane tab="测试员登录" key="tester">
                                <Form.Item>
                                    {getFieldDecorator('user', {
                                        rules: [{required: true, message: '请输入用户名！'}],
                                    })(<Input style={{width: 300}}
                                              prefix={<Icon type="user"/>}
                                              placeholder="用户名"
                                    />)}
                                </Form.Item>
                                <Form.Item>

                                    {getFieldDecorator('pwd', {
                                        rules: [{required: true, message: '请输入密码！'}],
                                    })(<Input style={{width: 300}}
                                              prefix={<Icon type="lock"/>}
                                              type="password"
                                              placeholder="密码"
                                    />)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{width: 300}}> 登录 </Button>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="管理员登录" key="admin">
                                <Form.Item>
                                    {getFieldDecorator('adminPwd', {
                                        rules: [{required: true, message: '请输入密码！'}]
                                    })(<Input style={{width: 300}}
                                              prefix={<Icon type="lock"/>}
                                              type="password"
                                              placeholder="密码"
                                    />)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{width: 300}}> 登录 </Button>
                                </Form.Item>
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </Router>
        )
            ;

    }
}

const loginForm = Form.create()(Login);

export default loginForm;
