import React from 'react';
import {BrowserRouter as Router, Link, Route} from "react-router-dom";

import '../App.css';
import './config';

import {Avatar, Col, Icon, Layout, Menu, Popover, Row} from 'antd';
import Testing from "./Testing";
import Settings from "./Settings";
import Histories from "./Histories";
import TestResult from "./TestResult";
import Users from "./Users";
import Login from "./Login";
import Main from "./Main";
import FailHistories from "./FailHistories";
import ChangePwd from "./ChangePwd";
import BaseComponent from "./BaseComponent";

const {Header, Sider} = Layout;
const {SubMenu} = Menu;

const menus = ["/testing", "/settings", "", "/users", "/histories"];

class App extends BaseComponent {
    constructor(props) {
        super(props)
    }

    state = {
        collapsed: false,
        logout: false,
    };

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    componentDidMount() {
        super.componentDidMount();

        const loggedUser = global.getUserLoggedIn();
        if (window.location.pathname !== '/login' && !loggedUser) {
            window.location.href = "/login";
        }
    };

    componentWillMount() {
        const path = window.location.pathname;
        const loggedUser = global.getUserLoggedIn();
        this.setState({
            current: (1 + menus.findIndex((a) => a === path)).toString(),
            user: loggedUser
        });
    };

    logout = () => {
        this.fetch({
            path: '/logout',
            success: () => {
                global.userLogout();
                global.constants.SESSION_ID = "";
                this.setState({logout: true});
            }
        })
    };

    render() {
        if (!this.state.logout) {
            return (
                <Router>
                    <Layout>
                        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                            <h2 className={"app-title"}>NB产测工具</h2>
                            <Menu theme="dark" mode="inline" onClick={this.handleClick}
                                  selectedKeys={[this.state.current]}
                                  openKeys={['3']}>
                                <Menu.Item key="1">
                                    <Icon type="play-circle"/>
                                    <span>测试</span>
                                    <Link to="/testing"></Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Icon type="setting"/>
                                    <span>设置</span>
                                    <Link to="/settings"></Link>
                                </Menu.Item>
                                <SubMenu key="3" title="历史记录">
                                    <Menu.Item key="5">
                                        <Icon type="history"/>
                                        <span>全部历史记录</span>
                                        <Link to="/histories"></Link>
                                    </Menu.Item>
                                    <Menu.Item key="6">
                                        <Icon type="close-circle"/>
                                        <span>不合格历史记录</span>
                                        <Link to="/failHistories"></Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key="4">
                                    <Icon type="user"/>
                                    <span>人员管理</span>
                                    <Link to="/users"></Link>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout>
                            <Header style={{height: '64px'}}>
                                <Row>
                                    <Col span={23}></Col>
                                    <Col span={1}>
                                        <Popover placement="bottom" content={
                                            <div>
                                                <Link onClick={this.logout} to="/login">登出</Link>
                                                <br/>
                                                <Link to="/changePwd">修改密码</Link>
                                            </div>
                                        } trigger="click">
                                            <Avatar style={{backgroundColor: '#999'}}
                                                    size="large">{this.state.user}</Avatar>
                                        </Popover>
                                    </Col>
                                </Row>
                            </Header>

                            <Route exact path="/" component={Main}/>
                            <Route path="/testing" component={Testing}/>
                            <Route path="/settings" component={Settings}/>
                            <Route path="/histories" component={Histories}/>
                            <Route path="/testResult" component={TestResult}/>
                            <Route path="/failHistories" component={FailHistories}/>
                            <Route path="/users" component={Users}/>
                            <Route path="/login" component={Login}/>
                            <Route path="/changePwd" component={ChangePwd}/>

                        </Layout>

                    </Layout>
                </Router>
            );
        } else {
            return (
                <Router>
                    <Route path="/login" component={Login}/>
                </Router>
            );
        }
    }
}

export default App;
