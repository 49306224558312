import React from 'react';
import reqwest from 'reqwest';
import './config';

import {message} from 'antd';


class BaseComponent extends React.Component {
    state = {
        loading: false,
    };

    fetch = (params) => {
        this.setState({loading: true});

        reqwest({
            url: global.constants.SERVICE_URL + params.path,
            method: params.method || 'post',
            data: params.data || {},
            type: params.type || 'json',
            crossOrigin: true,
            withCredentials: true,
            headers: {"x-auth-token": global.getSession()},
        }).then(data => {
            this.setState({loading: false});
            if (params.success) {
                params.success.call(this, data);
            }
        }, (rsp) => {
            this.setState({loading: false});
            if (rsp.status === 401) {
                message.error("登录失效，请重新登录！");
                setTimeout(() => {
                    window.top.location.href = "/login";
                }, 1000);
                return;
            }

            if (params.error) {
                params.error.call(this, rsp.responseText);
            } else {
                message.error(rsp.responseText);
            }
        }).always(() => {
            this.setState({
                processing: false,
            });
        });
    };

    componentDidMount(callback) {
        reqwest({
            url: '/config.js',
            type: 'json',
        }).then((data) => {
            global.constants.SERVICE_URL = data.server;
            if (callback) {
                callback.call(this);
            }
        });

        this.setState({loading: true});
    };

    render() {
        return {};
    };

    getQueryParam(name) {
        const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        const r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    };
}

export default BaseComponent;
