import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import BaseComponent from "./BaseComponent";

import '../App.css';

import {Button, Form, Input, message, Modal, Tabs} from 'antd';

const {TabPane} = Tabs;


class ChangePwd extends BaseComponent {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        super.componentDidMount();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            let oldPwd = values['oldPwd'];
            let newPwd = values['newPwd'];

            this.fetch({
                path: '/changePwd',
                data: {name: global.getUserLoggedIn(), oldPwd: oldPwd, newPwd: newPwd},
                success: (data) => {
                    message.success('修改成功，请重新登录');
                    window.location.href = '/';
                }
            });
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Router>

                <Modal
                    width={350}
                    visible={true}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer=""
                >

                    <Form layout="inline" style={{textAlign: 'center'}} onSubmit={this.handleSubmit}>
                        <Tabs onChange={this.onTabChange}>
                            <TabPane tab="修改密码">
                                <Form.Item label="旧密码">
                                    {getFieldDecorator('oldPwd', {
                                        rules: [{required: true, message: '请输入旧密码！'}],
                                    })(<Input type="password"/>)}
                                </Form.Item>
                                <Form.Item label="新密码">
                                    {getFieldDecorator('newPwd', {
                                        rules: [{required: true, message: '请输入新密码！'}],
                                    })(<Input type="password"/>)}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"> 确定 </Button>
                                </Form.Item>
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </Router>
        )
            ;

    }
}

const changePwdForm = Form.create()(ChangePwd);

export default changePwdForm;
