import React from 'react';
import '../App.css';
import './config';

import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Form,
    Input,
    InputNumber,
    Layout,
    Popconfirm,
    Radio,
    Row,
    Select,
    Table,
    Tabs
} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;
const {TabPane} = Tabs;
const {Option} = Select;

class Settings extends BaseComponent {
    constructor(props) {
        super(props);

        this.columns = [
            {
                title: '序号',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: '方案名称',
                dataIndex: 'name',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.name - b.name,
            },
            {
                title: '型号',
                dataIndex: 'model',
                filterMultiple: false,
                sorter: (a, b) => a.model - b.model,
            },
            {
                title: '电压',
                dataIndex: 'voltDesc',
                filterMultiple: false,
                sorter: (a, b) => a.voltDesc - b.voltDesc,
            },
            {
                title: '信号质量',
                dataIndex: 'signalDesc',
                filterMultiple: false,
                sorter: (a, b) => a.signalDesc - b.signalDesc,
            },
            {
                title: '软件版本',
                dataIndex: 'versionDesc',
                filterMultiple: false,
                sorter: (a, b) => a.versionDesc - b.versionDesc,
            },
            {
                title: '读数',
                dataIndex: 'meterValueDesc',
                filterMultiple: false,
                sorter: (a, b) => a.meterValueDesc - b.meterValueDesc,
            },
            {
                title: '上报间隔',
                dataIndex: 'rptIntervalDesc',
                filterMultiple: false,
                sorter: (a, b) => a.rptIntervalDesc - b.rptIntervalDesc,
            },
            {
                title: '采集周期',
                dataIndex: 'readIntervalDesc',
                filterMultiple: false,
                sorter: (a, b) => a.readIntervalDesc - b.readIntervalDesc,
            },
            {
                title: '操作',
                render: (text, record) => (
                    <span>
                <Popconfirm title="确认要删除?" onConfirm={() => this.handleDelete(record.id)}>
                    <a href="javascript:;">删除</a>
                </Popconfirm>
            </span>
                ),
            },
        ];
    }

    state = {
        data: [],
        pagination: {},
        loading: false,
        visible: false
    };

    fetchPlans = (params = {}) => {
        this.fetch({
            path: '/getTestPlans',
            success: (data) => {
                const pagination = {...this.state.pagination};
                pagination.total = data.length;
                this.setState({
                    dataSource: data,
                    pagination,
                });
            }
        });
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    closeDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    handleDelete = planId => {
        const dataSource = [...this.state.dataSource];
        this.fetch({
            path: '/delTestPlan',
            data: {planId: planId},
            success: (data) => {
                this.setState({dataSource: dataSource.filter(item => item.id !== planId)});
            }
        });
    };

    handleTestItemChange = e => {
        const checkState = {};
        checkState[e.target.id] = e.target.checked;

        this.setState(
            checkState,
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const name = values['name'];
            const model = values['model'];
            const voltState = values['voltState'];
            const volt = values['volt'];
            const signalState = values['signalState'];
            const signal1 = values['signal1'];
            const signal2 = values['signal2'];
            const meterValueType = values['meterValueType'];
            const meterValue = values['meterValue'];
            const meterValueState = values['meterValueState'];
            const rptIntervalState = values['rptIntervalState'];
            const rptType = values['rptType'];
            const rptInterval = values['rptInterval'];
            const readIntervalState = values['readIntervalState'];
            const readInterval = values['readInterval'];
            const version = values['version'];

            this.fetch({
                path: '/addTestPlan',
                data: {
                    name: name,
                    model: model,
                    volt: voltState && volt ? 'volt>=' + volt * 1000 : '',
                    voltDesc: voltState && volt ? '电压不低于' + volt + 'V' : '不限',
                    signal: signalState && signal1 && signal2 ? 'signal>=' + signal1 + '&&signal<=' + signal2 : '',
                    signalDesc: signalState && signal1 && signal2 ? '信号质量在' + signal1 + 'db和' + signal2 + 'db之间' : '不限',
                    meterValue: meterValueState ? (meterValueType === 1 ? 'value1>=0' : 'value1<=' + meterValue * 100) : '',
                    meterValueDesc: meterValueState ? (meterValueType === 1 ? '有读数' : '读数不高于' + meterValue) : '不限',
                    byHour: rptIntervalState ? 'byHour==' + (rptType === '小时') : '',
                    rptInterval: rptIntervalState && rptInterval ? 'rptInterval==' + rptInterval : '',
                    rptIntervalDesc: rptIntervalState && rptInterval ? rptInterval + rptType + '上报一次' : '不限',
                    readInterval: readIntervalState && readInterval ? 'readInterval==' + readInterval : '',
                    readIntervalDesc: readIntervalState && readInterval ? readInterval + '分钟采集一次' : '不限',
                    version: version ? "version=='" + version + "'" : '',
                    versionDesc: version ? version : '',
                },
                success: (data) => {
                    this.closeDrawer();
                    this.fetchPlans();
                }
            });
        });
    };

    componentDidMount() {
        super.componentDidMount(this.fetchPlans);
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Content
                    style={{
                        margin: '10px 10px',
                        padding: 24,
                        background: '#fff',
                        minHeight: 150,
                    }}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="测试方案" key="1">
                            <Row>
                                <Col span={20}></Col>
                                <Col span={4}>
                                    <Button style={{marginBottom: 16, float: 'right'}} type="primary"
                                            onClick={this.showDrawer}> 新增 </Button>
                                </Col>
                            </Row>
                            <Table columns={this.columns} dataSource={this.state.dataSource} onChange={this.onChange}/>
                        </TabPane>
                    </Tabs>

                    <Drawer
                        title="添加测试方案"
                        width={720}
                        onClose={this.closeDrawer}
                        visible={this.state.visible}
                    >
                        <Form layout="vertical" onSubmit={this.handleSubmit}>
                            <Form.Item label="方案名称：">
                                {getFieldDecorator('name', {
                                    rules: [{required: true, message: '请输入方案名称！'}]
                                })(<Input/>
                                )}
                            </Form.Item>
                            <Form.Item label="型号：">
                                {getFieldDecorator('model', {
                                    rules: [{required: true, message: '请输入型号！'}]
                                })(<Input/>
                                )}
                            </Form.Item>
                            <Form.Item label="测试项：" style={{marginBottom: 0}}>
                            </Form.Item>
                            <Form.Item>
                                <span>不低于</span>
                                {getFieldDecorator('volt', {
                                    rules: [{required: this.state.voltState, message: '请输入电压条件！'}]
                                })(<InputNumber min={1}
                                                style={{marginLeft: 10, marginRight: 10, width: 50}}/>)}
                                <span>V</span>
                                {getFieldDecorator('voltState')(
                                    <Checkbox style={{float: "left", margin: "6px 10px 0 0"}}
                                              onChange={this.handleTestItemChange}>电压：</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('signal1', {
                                    rules: [{required: this.state.signalState, message: '请输入信号质量条件！'}]
                                })(
                                    <InputNumber min={1}
                                                 style={{marginLeft: 10, marginRight: 10, width: 50}}/>
                                )}

                                <span>至</span>
                                {getFieldDecorator('signal2', {
                                    rules: [{required: this.state.signalState, message: '请输入信号质量条件！'}]
                                })(
                                    <InputNumber min={1}
                                                 style={{marginLeft: 10, marginRight: 10, width: 50}}/>
                                )}
                                <span>db之间</span>

                                {getFieldDecorator('signalState')(
                                    <Checkbox style={{float: "left", margin: "6px 10px 0 0"}}
                                              onChange={this.handleTestItemChange}>信号质量：</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('meterValueType', {
                                    rules: [{required: this.state.meterValueState, message: '请输入读数条件！'}]
                                })(
                                    <Radio.Group>
                                        <Radio value={1}>有读数</Radio>
                                        <Radio value={2}>不高于</Radio>

                                        {getFieldDecorator('meterValue')(
                                            <InputNumber min={1}
                                                         style={{marginLeft: 10, marginRight: 10, width: 50}}/>
                                        )}
                                        <span>吨</span>
                                    </Radio.Group>
                                )}

                                {getFieldDecorator('meterValueState')(
                                    <Checkbox style={{float: "left", margin: "6px 10px 0 0"}}
                                              onChange={this.handleTestItemChange}>读数：</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('rptInterval', {
                                    rules: [{required: this.state.rptIntervalState, message: '请输入上报间隔条件！'}]
                                })(
                                    <InputNumber min={1}
                                                 style={{marginLeft: 10, marginRight: 10, width: 50}}/>
                                )}
                                {getFieldDecorator('rptType', {initialValue: "天"})(
                                    <Select style={{width: 80}}>
                                        <Option value="天">天</Option>
                                        <Option value="小时">小时</Option>
                                    </Select>
                                )}
                                <span>上报一次</span>

                                {getFieldDecorator('rptIntervalState')(
                                    <Checkbox style={{float: "left", margin: "6px 10px 0 0"}}
                                              onChange={this.handleTestItemChange}>上报间隔：</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('readInterval', {
                                    rules: [{required: this.state.readIntervalState, message: '请输入采集周期条件！'}]
                                })(
                                    <InputNumber min={1}
                                                 style={{marginLeft: 10, marginRight: 10, width: 70}}/>
                                )}
                                <span>分钟采集一次</span>

                                {getFieldDecorator('readIntervalState')(
                                    <Checkbox style={{float: "left", margin: "6px 10px 0 0"}}
                                              onChange={this.handleTestItemChange}>采集周期：</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item label="软件版本号：">
                                {getFieldDecorator('version', {
                                    rules: [{required: true, message: '请输入软件版本号！'}]
                                })(<Input/>
                                )}
                            </Form.Item>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e9e9e9',
                                    padding: '10px 16px',
                                    background: '#fff',
                                    textAlign: 'left',
                                }}
                            >
                                <Button onClick={this.closeDrawer} style={{marginRight: 8}}>
                                    取消
                                </Button>
                                <Button htmlType="submit" type="primary">
                                    提交
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                </Content>

            </div>
        );
    }
}


const settingsForm = Form.create()(Settings);

export default settingsForm;
