import React from 'react';
import '../App.css';
import './config';

import {Button, Col, Drawer, Form, Input, Layout, Popconfirm, Row, Table, Tabs,} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;
const {TabPane} = Tabs;

class Users extends BaseComponent {
    constructor(props){
        super(props);

        this.columns = [
            {
                title: '序号',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: '账号',
                dataIndex: 'name',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.name - b.name,
            },
            {
                title: '备注',
                dataIndex: 'label',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.label - b.label,
            },
            {
                title: '添加时间',
                dataIndex: 'addTime',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.addTime - b.addTime,
            }, {
                title: '操作',
                render: (text, record) => (
                    <span>
                <Popconfirm title="确认要删除?" onConfirm={() => this.handleDelete(record.name)}>
                    <a href="javascript:;">删除</a>
                </Popconfirm>
            </span>
                ),
            },
        ];
    }

    state = {
        data: [],
        pagination: {},
    };

    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };

    fetchUsers = (params = {}) => {
        this.fetch({
            path: '/getUsers',
            success: (data) => {
                const pagination = {...this.state.pagination};
                pagination.total = data.length;
                this.setState({
                    data: data,
                    pagination,
                });
            }
        });
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    closeDrawer = () => {
        this.setState({
            visible: false,
        });
    };

    handleDelete = name => {
        const dataSource = [...this.state.data];
        this.fetch({
            path: '/delUser',
            data: {name: name},
            success: (data) => {
                this.setState({dataSource: dataSource.filter(item => item.name !== name)});
            }
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const name = values['name'];
            const pwd = values['pwd'];
            const label = values['label'];

            this.fetch({
                path: '/addUser',
                data: {
                    name: name,
                    pwd: pwd,
                    label: label,
                },
                success: (data) => {
                    this.closeDrawer();
                    this.fetchUsers();
                }
            });
        });
    };

    componentDidMount() {
        super.componentDidMount(this.fetchUsers);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Content
                    style={{
                        margin: '10px 10px',
                        padding: 24,
                        background: '#fff',
                    }}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="用户列表" key="1">
                            <Row>
                                <Col span={20}></Col>
                                <Col span={4}>
                                    <Button style={{marginBottom: 16, float: 'right'}} type="primary"
                                            onClick={this.showDrawer}> 新增 </Button>
                                </Col>
                            </Row>
                            <Table columns={this.columns} dataSource={this.state.data} onChange={this.onChange}/>
                        </TabPane>
                    </Tabs>

                    <Drawer
                        title="添加用户"
                        width={720}
                        onClose={this.closeDrawer}
                        visible={this.state.visible}
                    >
                        <Form layout="vertical" onSubmit={this.handleSubmit}>
                            <Form.Item label="用户名：">
                                {getFieldDecorator('name', {
                                    rules: [{required: true, message: '请输入用户名！'}]
                                })(<Input/>
                                )}
                            </Form.Item>
                            <Form.Item label="备注：">
                                {getFieldDecorator('label')(<Input/>
                                )}
                            </Form.Item>
                            <Form.Item label="密码：">
                                {getFieldDecorator('pwd', {
                                    rules: [{required: true, message: '请输入密码！'}]
                                })(<Input type="password"/>
                                )}
                            </Form.Item>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e9e9e9',
                                    padding: '10px 16px',
                                    background: '#fff',
                                    textAlign: 'left',
                                }}
                            >
                                <Button onClick={this.closeDrawer} style={{marginRight: 8}}>
                                    取消
                                </Button>
                                <Button htmlType="submit" type="primary">
                                    提交
                                </Button>
                            </div>
                        </Form>
                    </Drawer>

                </Content>
            </div>
        );
    }
}

const userForm = Form.create()(Users);

export default userForm;
