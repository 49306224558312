import React from 'react';
import '../App.css';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import './config';
import Moment from 'moment'

import {
    Button,
    Col,
    DatePicker,
    Form,
    Icon,
    Input,
    Layout,
    message,
    Row,
    Select,
    Spin,
    Statistic,
    Steps,
    Table,
    Upload
} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;
const {Option} = Select;
const {Step} = Steps;
const ButtonGroup = Button.Group;

const columns = [
    {
        title: '表号',
        dataIndex: 'mac',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.mac - b.mac,
    },
    {
        title: '结果',
        dataIndex: 'testResult',
        filterMultiple: false,
        sorter: (a, b) => a.testResult - b.testResult,
        render: (text, record) => (
            <span style={{color: record.testResult === "通过" ? "#52c41a" : "red"}}>{text}</span>
        ),
    },
    {
        title: 'IMEI',
        dataIndex: 'imei',
        filterMultiple: false,
        sorter: (a, b) => a.imei - b.imei,
        render: (text, record) => (
            <span style={{wordBreak: 'break-all'}}>{text}</span>
        ),
    },
    {
        title: 'IMSI',
        dataIndex: 'imsi',
        filterMultiple: false,
        sorter: (a, b) => a.imsi - b.imsi,
        render: (text, record) => (
            <span style={{wordBreak: 'break-all'}}>{text}</span>
        ),
    },
    {
        title: 'ICCID',
        dataIndex: 'ccid',
        filterMultiple: false,
        sorter: (a, b) => a.ccid - b.ccid,
        render: (text, record) => (
            <span style={{wordBreak: 'break-all'}}>{text}</span>
        ),
    },
    {
        title: '电压',
        dataIndex: 'volt',
        filterMultiple: false,
        sorter: (a, b) => a.volt - b.volt,
        render: (text, record) => (
            <span style={{color: record.voltOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '信号质量',
        dataIndex: 'signal',
        filterMultiple: false,
        sorter: (a, b) => a.signal - b.signal,
        render: (text, record) => (
            <span style={{color: record.signalOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '版本号',
        dataIndex: 'version',
        filterMultiple: false,
        sorter: (a, b) => a.version - b.version,
        render: (text, record) => (
            <span style={{color: record.versionOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '读数',
        dataIndex: 'meterValue',
        filterMultiple: false,
        sorter: (a, b) => a.meterValue - b.meterValue,
        render: (text, record) => (
            <span style={{color: record.meterValueOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '上报时间',
        dataIndex: 'reportTime',
        filterMultiple: false,
        sorter: (a, b) => a.reportTime - b.reportTime,
        render: (text, record) => (
            <span>{!text ? "" : new Date(parseInt(text)).toLocaleString()}</span>
        ),
    },
    {
        title: '上报周期',
        dataIndex: 'byHour',
        filterMultiple: false,
        sorter: (a, b) => a.byHour - b.byHour,
        render: (text, record) => (
            <span style={{color: record.byHourOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '上报间隔',
        dataIndex: 'rptInterval',
        filterMultiple: false,
        sorter: (a, b) => a.rptInterval - b.rptInterval,
        render: (text, record) => (
            <span style={{color: record.rptIntervalOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '采集周期',
        dataIndex: 'readInterval',
        filterMultiple: false,
        sorter: (a, b) => a.readInterval - b.readInterval,
        render: (text, record) => (
            <span style={{color: record.readIntervalOk ? "" : "red"}}>{text}</span>
        ),
    },
];

const uploadProps = {
    name: 'file',
    accept: '.xls',
    action: () => {
        return global.constants.SERVICE_URL + '/addTestDevices';
    },
    headers: {"x-auth-token": global.getSession()},
    crossOrigin: true,
    withCredentials: true,
    showUploadList: false,
};

let fetchTestResultTimer;

class Testing extends BaseComponent {
    state = {
        dataSource: [],
        pagination: {},
        batchName: '',
        testPlans: [],
        summery: {},
        byUpload: false,
    };

    handleChange = e => {

    };
    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };

    fetchTestPlans = (params = {}) => {
        this.fetch({
            path: '/getTestPlans',
            success: (data) => {
                this.setState({
                    testPlans: data,
                });
            }
        });
    };

    fetchTestResult = (params = {}) => {
        clearTimeout(fetchTestResultTimer);

        this.fetch({
            path: '/getTestHistory',
            data: {batch: this.state.batchName},
            success: data => {
                this.setState({summery: data});
            }
        });

        this.fetch({
            path: '/getTestResults',
            data: {batch: this.state.batchName},
            success: (data) => {
                const pagination = {...this.state.pagination};
                pagination.total = data.length;
                this.setState({
                    dataSource: data,
                });

                //3秒后再次获取测试结果
                fetchTestResultTimer = setTimeout(this.fetchTestResult, 3000);
            }
        });
    };

    startTest = (params) => {
        clearTimeout(fetchTestResultTimer);

        this.fetch({
            path: '/startTest',
            data: {planId: params.planId, batch: params.batch, beginTime: params.beginTime},
            success: (data) => {
                this.setState({batchName: params.batch,});
                this.fetchTestResult();
            }
        });
    };

    onFinishTest = () => {
        clearTimeout(fetchTestResultTimer);
        this.fetch({
            path: '/finishTest',
            data: {batch: this.state.batchName},
            success: (data) => {
                this.props.form.setFieldsValue({batchName: ""});
                this.props.form.setFieldsValue({beginTime: ""});
                this.setState({summery: {}, dataSource: []})
            }
        });
    };

    onExcelUploadChange(info) {
        const status = info.file.status;
        if (status === "uploading") {
            this.setState({loading: true});
        } else if (status === "done") {
            const batch = info.file.name.replace(".xls", "");
            this.setState({batchName: batch, loading: false, byUpload: true});
            if (batch !== info.file.response) {
                message.error('上传失败:' + info.file.response);
                return;
            }
            this.props.form.setFieldsValue({batchName: batch});
            this.fetchTestResult();
        }
    };

    macInputChange = (e) => {
        const mac = e.target.value;
        if (mac && mac.length === 9) {
            const data = this.state.dataSource;
            data.push({mac: mac});
            this.setState({
                dataSource: data,
                byUpload: false
            });

            e.target.value = '';
        }
    };

    onBeginDateChange = (date, dateString) => {
        console.log(date, dateString);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const planId = values["planId"];
            const batch = values["batchName"];
            const beginTime = values["beginTime"].format('YYYY-MM-DD HH:mm:ss');

            if (!this.state.byUpload) {
                this.fetch({
                    path: '/addTestDevice',
                    data: {batch: batch, macs: this.state.dataSource.map(d => d.mac).join(',')},
                    success: (data) => {
                        this.startTest({planId: planId, batch: batch, beginTime: beginTime});
                    }
                });
            } else {
                this.startTest({planId: planId, batch: batch, beginTime: beginTime});
            }

        });
    };

    componentDidMount() {
        const batch = decodeURIComponent(this.getQueryParam("batch"));
        const planId = this.getQueryParam("planId");
        const testTime = this.getQueryParam("testTime");

        if (batch && planId && testTime) {
            this.props.form.setFieldsValue({batchName: batch});
            this.setState({batchName: batch, planId: parseInt(planId), testTime: Moment(testTime), byUpload: true});
        }

        super.componentDidMount(() => {
            if (this.state.byUpload) {
                this.fetchTestResult();
            }
            this.fetchTestPlans();
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Spin tip="请等待..." spinning={this.state.loading}>
                    <Form layout="inline" onSubmit={this.handleSubmit}>
                        <Content
                            style={{
                                margin: '10px 10px',
                                padding: 24,
                                background: '#fff',
                                minHeight: 130,
                            }}
                        >
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="输入表号：">
                                        {getFieldDecorator("mac")(
                                            <Input onChange={this.macInputChange} placeholder=""/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="或批量导入">
                                        <Upload {...uploadProps} onChange={(e) => this.onExcelUploadChange(e)}>
                                            <Button>
                                                <Icon type="upload"/>上传excel
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <a href="/template.xls">下载模板</a>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="测试方案：">
                                        {getFieldDecorator('planId', {
                                            initialValue: this.state.planId,
                                            rules: [{
                                                required: true,
                                                message: '请选择测试方案！'
                                            }],
                                        })(
                                            <Select placeholder="请选择" style={{width: 120}} onChange={this.handleChange}>
                                                {this.state.testPlans.map(p => (
                                                    <Option value={p.id}>{p.name}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginTop: "20px"}}>
                                <Col span={2}> 测试步骤：</Col>
                                <Col span={12}>
                                    <Steps size="small">
                                        <Step title="选择测试方案"/>
                                        <Step title="激活设备"/>
                                        <Step title="输/导入表号"/>
                                        <Step title="填写批次号、起测时间"/>
                                        <Step title="执行测试"/>
                                    </Steps>
                                </Col>
                            </Row>
                        </Content>

                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                background: '#fff',
                            }}
                        >
                            <Row>
                                <Col span={6}>
                                    <Row>
                                        <Col span={6}>
                                            <Statistic title="设备数" value={this.state.summery.total}/>
                                        </Col>
                                        <Col span={6}>
                                            <Statistic title="通过" value={this.state.summery.passCount}/>
                                        </Col>
                                        <Col span={6}>
                                            <Statistic title="不通过" value={this.state.summery.failCount}/>
                                        </Col>
                                        <Col span={6}>
                                            <Statistic title="未找到表号" value={this.state.summery.missCount}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={18}>
                                    <Form.Item label="批次名称：">
                                        {getFieldDecorator('batchName', {
                                            rules: [{
                                                required: true,
                                                message: '请填写批次名称！'
                                            }],
                                        })(<Input placeholder=""/>)}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('beginTime', {
                                            initialValue: this.state.testTime,
                                            rules: [{
                                                required: true,
                                                message: '请选择起始测试时间！'
                                            }],
                                        })(<DatePicker placeholder="选择起始测试时间"
                                                       locale={locale} showTime={true}
                                                       onChange={this.onBeginDateChange}/>)}
                                    </Form.Item>
                                    <Form.Item>
                                        <ButtonGroup>
                                            <Button type="primary" htmlType="submit"><Icon
                                                type="play-circle"/>执行测试</Button>
                                            <Button type="danger" onClick={this.onFinishTest}><Icon
                                                type="stop"/>结束测试</Button>
                                        </ButtonGroup>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Table columns={columns} dataSource={this.state.dataSource} onChange={this.onChange}/>
                        </Content>
                    </Form>
                </Spin>
            </div>
        );
    }
}

const testingForm = Form.create()(Testing);

export default testingForm;
