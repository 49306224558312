import React from 'react';

import '../App.css';

import {Layout, Typography} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;
const {Title, Paragraph, Text} = Typography;

class Main extends BaseComponent {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        super.componentDidMount();

        const userLoggedIn = global.getUserLoggedIn();
        this.setState({loginSuccess: userLoggedIn});
    };

    render() {
        return (
            <Content
                style={{
                    margin: '10px 10px',
                    padding: 24,
                    background: '#fff',
                    minHeight: 150,
                }}
            >
                <Typography>
                    <Title>使用说明</Title>
                    <Paragraph>
                        NB产测工具。
                    </Paragraph>
                    <Paragraph>
                        请优先使用谷歌浏览器打开。
                    </Paragraph>
                </Typography>
            </Content>
        );

    }
}

export default Main;
