import React from 'react';
import '../App.css';
import './config';

import {Button, Col, Layout, Row, Statistic, Table} from 'antd';
import BaseComponent from "./BaseComponent";

const {Content} = Layout;
const ButtonGroup = Button.Group;

const columns = [
    {
        title: '表号',
        dataIndex: 'mac',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.mac - b.mac,
    },
    {
        title: '结果',
        dataIndex: 'testResult',
        filterMultiple: false,
        sorter: (a, b) => a.testResult - b.testResult,
        render: (text, record) => (
            <span style={{color: record.testResult === "通过" ? "#52c41a" : "red"}}>{text}</span>
        ),
    },
    {
        title: 'IMEI',
        dataIndex: 'imei',
        filterMultiple: false,
        sorter: (a, b) => a.imei - b.imei,
        render: (text, record) => (
            <span style={{wordBreak: 'break-all'}}>{text}</span>
        ),
    },
    {
        title: 'IMSI',
        dataIndex: 'imsi',
        filterMultiple: false,
        sorter: (a, b) => a.imsi - b.imsi,
        render: (text, record) => (
            <span style={{wordBreak: 'break-all'}}>{text}</span>
        ),
    },
    {
        title: 'ICCID',
        dataIndex: 'ccid',
        filterMultiple: false,
        sorter: (a, b) => a.ccid - b.ccid,
        render: (text, record) => (
            <span style={{wordBreak: 'break-all'}}>{text}</span>
        ),
    },
    {
        title: '电压',
        dataIndex: 'volt',
        filterMultiple: false,
        sorter: (a, b) => a.volt - b.volt,
        render: (text, record) => (
            <span style={{color: record.voltOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '信号质量',
        dataIndex: 'signal',
        filterMultiple: false,
        sorter: (a, b) => a.signal - b.signal,
        render: (text, record) => (
            <span style={{color: record.signalOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '版本号',
        dataIndex: 'version',
        filterMultiple: false,
        sorter: (a, b) => a.version - b.version,
        render: (text, record) => (
            <span style={{color: record.versionOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '读数',
        dataIndex: 'meterValue',
        filterMultiple: false,
        sorter: (a, b) => a.meterValue - b.meterValue,
        render: (text, record) => (
            <span style={{color: record.meterValueOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '上报时间',
        dataIndex: 'reportTime',
        filterMultiple: false,
        sorter: (a, b) => a.reportTime - b.reportTime,
        render: (text, record) => (
            <span>{(text + "").replace(/(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/g, "20$1-$2-$3 $4:$5:$6")}</span>
        ),
    },
    {
        title: '上报周期',
        dataIndex: 'byHour',
        filterMultiple: false,
        sorter: (a, b) => a.byHour - b.byHour,
        render: (text, record) => (
            <span style={{color: record.byHourOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '上报间隔',
        dataIndex: 'rptInterval',
        filterMultiple: false,
        sorter: (a, b) => a.rptInterval - b.rptInterval,
        render: (text, record) => (
            <span style={{color: record.rptIntervalOk ? "" : "red"}}>{text}</span>
        ),
    },
    {
        title: '采集周期',
        dataIndex: 'readInterval',
        filterMultiple: false,
        sorter: (a, b) => a.readInterval - b.readInterval,
        render: (text, record) => (
            <span style={{color: record.readIntervalOk ? "" : "red"}}>{text}</span>
        ),
    },
];

const planColumns = [
    {
        title: '方案名称',
        dataIndex: 'name',
    },
    {
        title: '型号',
        dataIndex: 'model',
    },
    {
        title: '电压',
        dataIndex: 'voltDesc',
    },
    {
        title: '信号质量',
        dataIndex: 'signalDesc',
    },
    {
        title: '软件版本',
        dataIndex: 'versionDesc',
    },
    {
        title: '读数',
        dataIndex: 'meterValueDesc',
    },
    {
        title: '上报间隔',
        dataIndex: 'rptIntervalDesc',
    },
    {
        title: '采集周期',
        dataIndex: 'readIntervalDesc',
    },
];

class TestResult extends BaseComponent {
    state = {
        data: [],
        pagination: {},
        batch: "",
        summery: {}
    };

    onChange = (pagination, filters, sorter) => {
        console.log('params', pagination, filters, sorter);
    };

    fetchSummery = (params = {}) => {
        this.fetch({
            path: '/getTestHistory',
            data: {batch: decodeURIComponent(params.batch)},
            success: data => {
                this.setState({summery: data});
                this.fetchPlan(data);
            }
        });
    };

    fetchPlan = (params = {}) => {
        this.fetch({
            path: '/getTestPlan',
            data: {planId: params.planId},
            success: (data) => {
                this.setState({
                    planDataSource: [data],
                });
            }
        });
    };

    fetchTestResults = (params = {}) => {
        this.fetch({
            path: '/getTestResults',
            data: {batch: decodeURIComponent(params.batch)},
            success: (data) => {
                const pagination = {...this.state.pagination};
                pagination.total = data.length;
                this.setState({
                    dataSource: data,
                    pagination,
                });
            }
        });
    };

    reTest = () => {
        window.location.href = "/testing?batch=" + encodeURIComponent(this.state.summery.batch) + "&planId=" +
            this.state.summery.planId + "&testTime=" + this.state.summery.startTime;
    };

    componentDidMount() {
        const batch = this.props.location.search.replace("?", "");
        this.setState({loading: true, batch: batch});

        super.componentDidMount(() => {
            this.fetchTestResults({batch: batch});
            this.fetchSummery({batch: batch});
        });

    }

    render() {
        return (
            <div>
                <div
                    style={{
                        margin: '10px 10px',
                        padding: 24,
                        background: '#fff',
                    }}>
                    <form target={"_blank"} method={"post"}
                          action={global.constants.SERVICE_URL + "/exportTestResult"}>
                        <input type={"hidden"} name={"batch"} value={this.state.summery.batch}/>
                        <Row>
                            <Col span={3}>
                                <Statistic title="批次" value={this.state.summery.batch}/>
                            </Col>
                            <Col span={2}>
                                <Statistic title="设备数" value={this.state.summery.total}/>
                            </Col>
                            <Col span={2}>
                                <Statistic title="通过" value={this.state.summery.passCount}/>
                            </Col>
                            <Col span={2}>
                                <Statistic title="不通过" value={this.state.summery.failCount}/>
                            </Col>
                            <Col span={2}>
                                <Statistic title="未找到表号" value={this.state.summery.missCount}/>
                            </Col>
                            <Col span={2}>
                                <Statistic title="测试人" value={this.state.summery.tester}/>
                            </Col>
                            <Col span={3}>
                                <Statistic title="测试方案" value={this.state.summery.planName}/>
                            </Col>
                            <Col span={5}>
                                <Statistic title="测试时间" value={this.state.summery.startTime}/>
                            </Col>
                            <Col span={3}>
                                <ButtonGroup>
                                    <Button type="primary" htmlType={"submit"}> 导出 </Button>
                                    <Button type="danger" onClick={this.reTest}> 重测 </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </form>
                    <Table title={() => "测试方案"} columns={planColumns} dataSource={this.state.planDataSource}
                           pagination={false}/>
                </div>
                <Content
                    style={{
                        margin: '10px 10px',
                        padding: '0px 24px 24px 24px',
                        background: '#fff',
                    }}
                >
                    <Table title={() => "测试结果明细"} columns={columns} dataSource={this.state.dataSource}
                           onChange={this.onChange}/>
                </Content>
            </div>
        );
    }
}

export default TestResult;
