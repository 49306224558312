global.userLoggedIn = function (user) {
    localStorage.setItem("userLoggedIn", user);
};
global.userLogout = function () {
    localStorage.removeItem("userLoggedIn");
};
global.getUserLoggedIn = function () {
    return localStorage.getItem("userLoggedIn");
};

global.setSession = function (id) {
    localStorage.setItem("sessionId", id);
};
global.getSession = function () {
    return localStorage.getItem("sessionId");
};

global.logout = function () {
    window.top.location.href = "/login";
};


global.constants =
    {
        SERVICE_URL: "",
        SESSION_ID: "",
    };
